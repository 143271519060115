import React from 'react'
import { Select, Button } from "@mantine/core";

function FileInputButton({setFilename, gettext, setInputText}) {


    const handleChange = (e) => {
        const file = e.target.files[0];
        console.log(file);
        setFilename(file.name)
        const fileUrl = URL.createObjectURL(file)
        gettext(fileUrl).then(function (text) {
          setInputText(text)
          console.log(text);
        },
          function (reason) {
            console.error(reason);
          });
      }

    return (
        <div className="file-input-button-container" style={{ display: "grid" , cursor:"pointer", marginTop:"40px", overflow:"hidden"}}>
            <Button className="file-input-button" style={{ gridRow: 1, gridColumn: 1, width: "320px", borderRadius: 20, height: 44, backgroundColor: "#ff5831", cursor:"pointer",
          }} type='file'>
                Drop your file or click to upload
            </Button>
            <input onChange={(e) => { handleChange(e) }} style={{ gridRow: 1, gridColumn: 1, opacity: 0, width: "320px", height: "44px", cursor:"pointer", paddingLeft:"100%"}} type="file" />
        </div>)
}

export default FileInputButton