import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom"
import HomePage from './Components/HomePage.tsx';
import ContactPage from './Components/ContactPage';
import Menu from './Components/Menu';
import '@mantine/core/styles.css';
import { MantineProvider } from '@mantine/core';
import LoadingScreen from './Components/LoadingScreen.tsx';
import AppContextProvider from './context/context.tsx';
import ResultPage from './Components/ResultPage.tsx';
import * as Swetrix from 'swetrix'
import HowItWorks from './Components/HowItWorks.tsx'
function App() {
  Swetrix.init('ciSlU1rY6B8a')
  Swetrix.trackViews()
  return (
    <AppContextProvider>
      <MantineProvider>
        <div className='App' style={{
          height: "100vh", width: "100vw",
          backgroundImage:
            `linear-gradient(
              rgb(246, 216, 212) 0%, 
              rgba(255, 255, 255, 1) 25%,
              rgba(255, 255, 255, 0) 25%`
        }}>
          <Router>
            <Menu />
            <Routes>
              <Route exact path="/" Component={HomePage} />
              <Route exact path="/contact" Component={ContactPage} />
              <Route exact path="/loading" Component={LoadingScreen} />
              <Route exact path="/result" Component={ResultPage} />
              <Route exact path="/about" Component={HowItWorks} />
            </Routes>
          </Router>
        </div>
      </MantineProvider>
    </AppContextProvider>
  );
}

export default App;
