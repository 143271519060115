import { Button } from "@mantine/core";
import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AppContext from '../context/createContext.tsx';
import generateUnicode from '../Utils/unicodeGenerator.ts';

function SendTextButton({ inputText, language, cefrLevel }) {
  const navigate = useNavigate()
  const {
    resultText: [ resultText , setResultText],
    unicode: [, setUnicode],
    textLength : [ textLength, setTextLength],
    newWords: [newWords, setNewWords],
    newGrammarList: [newGrammarList, setNewGrammarList]
  } = useContext(AppContext)!;


  /* Handles the connection between the frontend and server. First, splits the text to array of subarray of strings, then sends a POST request
    to upload the user input to server. Then, repeatedly sends GET request to retrieve the results. 
  */
  const onClickHandler = () => {
    const resultArrays = splitTextIntoArrays(inputText);
    setTextLength(resultArrays.length)
    

    const requestHeaders = new Headers();
    requestHeaders.append("Content-Type", "application/json");

    const requestIdentifier = generateUnicode()
    setUnicode(requestIdentifier)

    const raw = JSON.stringify({
      "language": language,
      "cefr_level": cefrLevel,
      "text_list": resultArrays,
      "unicode": requestIdentifier
    });

    fetch("/api/text/", requestOptions("POST",requestHeaders, raw))
      .then(_ =>
        sendRequest(requestIdentifier,requestOptions("GET", requestHeaders), navigate, setResultText, resultText ,resultArrays.length , setNewWords, setNewGrammarList )
      );
    navigate("/loading");
  }


  return (
    <Button onClick={onClickHandler} style={{ width: "150px", borderRadius: 20, height: 44, backgroundColor: "#ff5831", fontWeight: "normal", marginLeft:"auto", marginTop:"1rem" }}>
      Let's read!
    </Button>
  )
}

export default SendTextButton





//Server requires the input to be an array of subarrays of strings, with each array index to have max 500 words. Below function handles it 
export function splitTextIntoArrays(text) {
  // Split the text into an array of words
  const words = text.split(/\s+/);
  const wordsPerArray = 500
  // Group the words into subarrays
  const resultArrays : String[] = [];
  for (let i = 0; i < words.length; i += wordsPerArray) {
    const subarray = words.slice(i, i + wordsPerArray);
    resultArrays.push(subarray.join(' '));
  }

  return resultArrays;
}

function processNewText(text: string, wordList: {[key: string]: string;}): string{
  let result = text;
  Object.keys(wordList).forEach(word => {
    result = result.replace(word, `<Mark data-title="${wordList[word]}">${word}</Mark>`)
  });
  
  return result;
}

function getRandomGrammarEntries(wordList){
  const keys = Object.keys(wordList);
  const randomIndex = Math.floor(Math.random() * Object.keys(wordList).length);
  const randomKey = keys[randomIndex];
  return { [randomKey]: wordList[randomKey] };
}

/* Function to send a GET request, to receive the text from server. Since server returns the results incrementally, 
  we have to send the request until we get all the data. In case of error, go back to home screen. After we get the first successful GET response from server, navigate to result screen
*/
const sendRequest = (requestIdentifier, requestOptions, navigate, setResultText,resultText, textLength, setNewWords, setNewGrammarList) => {
  fetch(`/api/text/?unicode=${requestIdentifier}`, requestOptions)
    .then(response => {
      
      response.json()
        .then(res => {
          console.log(res.message);
          if (res.message!="waiting") {
            console.log(res.data);
            const textArr = res.data
            if (textArr.length==1) {
              navigate("/result");
            }
            let texts = textArr.map(data => processNewText(data.text, data.word_list))
            setResultText(texts)
            setNewWords(textArr.map(data=>data.word_list))
            setNewGrammarList(old => {
              return [...old, getRandomGrammarEntries(textArr[textArr.length-1]?.grammar_list)]
            })
          }
          if (res.data?.length) {
            //The length parameter allows us to see if we need to send more requests. If the retrieved data has same length as input, then the response is finished.
            if ( !(res.data.length==textLength))
              setTimeout(() => sendRequest(requestIdentifier, requestOptions, navigate, setResultText, resultText,textLength,setNewWords, setNewGrammarList), 15000);
          }
          else {
            setTimeout(() => sendRequest(requestIdentifier, requestOptions, navigate, setResultText, resultText ,textLength, setNewWords, setNewGrammarList), 15000);
          } 
        });
    })
    .catch(error => {
      console.log('error', error)
      navigate("/")
    })
}

const requestOptions = (method: string, headers:Headers, body?) => {
  return {
    method,
    headers,
    body,
    redirect: 'follow'
  }
};

    // /api/