import React from 'react'
import styled from 'styled-components';
import {Button} from "@mantine/core"
const MainText = styled.div`
    font-size:26px;
    font-weight:bold;
    line-height:130%;
    font-family: Montserrat;
    @media (max-width: 768px) { 
      text-align: center;
    }
    
`
const Flex = styled.div`
  display:flex;
  flex-direction: column;
  margin: 5% 16vw;
  `

const SecondaryText = styled.div`
  font-size:14px;
  font-weight:500;
  font-family: Montserrat;
  color: black;
  margin-top:0px;
`


function ContactPage() {
  return (
    <Flex>
      <MainText>
        Contact us
      </MainText>
      <SecondaryText style={{marginTop:"15px"}}>
        Let's get in touch!
      </SecondaryText>
      <textarea style={{border:"1px solid #d8d8d8", width:"60%", height:"60px", borderRadius:"40px", marginTop:"30px", resize:"none", padding:"15px"}} placeholder='Your name'></textarea>
      <textarea style={{border:"1px solid #d8d8d8", width:"60%", height:"60px", borderRadius:"40px", marginTop:"15px", resize:"none", padding:"15px"}} placeholder='Your email'></textarea>
      <textarea style={{border:"1px solid #d8d8d8", width:"60%", height:"220px", borderRadius:"30px", marginTop:"15px", resize:"none", padding:"15px"}} placeholder='Your message'></textarea>
      <Button onClick={() => {
            }
            }
             style={{ width: "150px", borderRadius: 20, height: "44px", backgroundColor: "#ff5831", marginTop: "15px" }}>
                Send
            </Button>
    </Flex>
  )
}

export default ContactPage