import React from 'react'
import styled from "styled-components"

const MainContainer = styled.div`
    display:flex;
    margin-left:20%;
`

const LeftContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    width:50%;
`

const MainText = styled.text`
    font-size:26px;
    font-weight:bolder;
    line-height:130%;
    font-family: Montserrat;
    margin-top:10%;
`

const SecondaryText = styled.text`
    font-size:14px;
    max-width:400px;
    margin-top:10px;
    font-family: Montserrat;
`

function LoadingScreen() {
    return (
        <MainContainer>
            <LeftContainer>
                <img style={{ marginTop: "35%" }} alt="loading icon" src='V.gif'></img>
                <MainText>
                    Your book is being processed
                </MainText>
                <SecondaryText>
                    Please, do not close this tab
                </SecondaryText>
            </LeftContainer>
        </MainContainer>
    )
}

export default LoadingScreen