import React, {useState} from 'react'
import styled from "styled-components"
import { useDisclosure } from '@mantine/hooks';
import { Container, Group, Burger, Drawer, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom';


const links = [
    { link: '/', label: 'Choose a book' },
    { link: '/about', label: 'How it works' },
    { link: '/contact', label: 'Contact us' },
  ];

const LogoImage = styled.img`
    max-height:80px;
    @media (min-width: 768px) { 
      margin-left:15vw 
    }
    @media (max-width: 768px) { 
      padding:1rem 
    }
`

function Menu() {

    const navigate = useNavigate()
    const [opened, handlers] = useDisclosure(false, {
        pen: () => console.log('Opened'),
        onClose: () => console.log('Closed'),
    });
    const [active, setActive] = useState("");

    const linkItems = links.map((link) => (
        <a
          key={link.label}
          href={link.link}
          className='header__link'
          data-active={active === link.link || undefined}
          onClick={(event) => {
            event.preventDefault();
            navigate(link.link)
            setActive(link.link);
          }}
        >
          {link.label}
        </a>
      ));

    return (
        <header className="header">
            <Container className="header__inner" miw="100vw">
                <LogoImage src='lecvi_128x128.png' alt="Logo" style={{cursor:"pointer"}} onClick={()=>navigate("/")}></LogoImage>
                <Group mr="15vw" gap={20} visibleFrom="xs">
                    {linkItems}
                </Group>
                <Burger opened={opened} onClick={handlers.toggle} hiddenFrom="xs" size="sm" />
            </Container>
            <Drawer 
              className='header__mobile-menu'
              opened={opened} 
              onClose={handlers.close}
              overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
              position="right"
            >
              <Stack gap={20}>
                {linkItems}
              </Stack>
            </Drawer>
        </header>
    )
}

export default Menu