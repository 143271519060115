import React, { useContext } from 'react'
import styled from "styled-components"
import InstructionCircle from './InstructionCircle.jsx'
import { Select } from "@mantine/core";
import * as pdfjs from "pdfjs-dist";
import * as worker from 'pdfjs-dist/build/pdf.worker.mjs'
import { useState } from "react";
import FileInputButton from './FileInputButton.tsx';
import SendTextButton from './SendTextButton.tsx';
import { CloseButton } from '@mantine/core';
import { Switch } from '@mantine/core';
const Main = styled.div`
  display:flex;
  width:100vw;
  gap:40px;
  @media (max-width: 768px) { 
    flex-direction: column;
  }
  `
const MainContainer = styled.div`
  flex:1;
  padding-top:5%;
  padding-left: 0.5rem;
  padding-right: 0.5rem; 
  
  `

const MainContainerInner = styled.div`
    display:flex;
    flex:1;
    @media (min-width: 768px) { 
      width:80%;
    }
    flex-direction:column;
    align-items: flex-start;
    @media (max-width: 768px) { 
      align-items: stretch;
    }
`

const ImageContainer = styled.div`
  display:flex;
  flex:1;
  padding-top:2.75%;
  justify-content:center;
`

const MainImage = styled.img`
  @media (min-width: 768px) { 
    margin-left:15vw;
    height: 30vw;
  };
  width: 33vw
`

const MainText = styled.div`
    font-size:26px;
    font-weight:bold;
    line-height:130%;
    font-family: Montserrat;
    @media (max-width: 768px) { 
      text-align: center;
    }
    
`
// margin-left:15px;

const SecondaryText = styled.div<{ $marginTop?: number; $color?: string, $fontWeight?: number }>`
  font-size:14px;
  font-weight:${props => props.$fontWeight || 500};
  font-family: Montserrat;
  color:${props => props.$color || "black"};
  margin-top:${props => props.$marginTop || 0}px;
`

const SecondaryTextContainer = styled.div`
  display:flex;
  flex-direction:column;
  margin-top:20px;
  align-items:flex-start;
  justify-content:center;
  @media (max-width: 768px) { 
    align-items: center;
  }
`

const InstrFlex = styled.div`
  display:flex;
  gap:24px;
  align-items:center;
  justify-content:center;
  margin-top:30px;
`
const InstrText = styled.div`
  font-size:14px; 
  font-weight:600;
  margin-left:15px;
  margin-right:10px;
  font-family: Montserrat;
  `

const OptionsContainer = styled.div`
  display:flex;
  width:100%;
  flex: 0 0 100%;
  margin-top:20px;
  gap:10px;
`
// margin-left:10px;


const FilenameContainer = styled.div`
  margin-top:40px;
  display:flex;
  align-items:center;
  justify-content:center;
`

const Flex = styled.div <{ $gap?: number; }>`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  gap:${props => props.$gap || 0}px
`

const TextArea = styled.textarea`
  padding: 10px;
  margin: 10px 0;
  width:100%;;
  resize: vertical;
  min-height:150px;
  background-color:#fff;
  margin-top:20px;
  border: 1px solid #9b8780; /* Example border color */
  border-radius: 5px;
  &::placeholder {
    color:#2d2d2d
  };
  
&:focus {
    border: 1px solid #9b8780;
    outline: none;
    box-shadow: 0 0 8px #9b8780;
  }
`;

// box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); /* Add a glow effect on focus */
// &:focus {
//   border-color: #c62500; /* Darker border color on focus */
//   outline: none; /* Remove default focus outline */
//   box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); /* Add a glow effect on focus */
// }
// box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */


/*   
  PDF processing part. Reads pdf from input file, converts to plain string.
  Just adding pdfjs as npm package raised some errors, so Im setting a worker thread myself.
*/
pdfjs.GlobalWorkerOptions.workerSrc = worker

async function gettext(pdfUrl) {
  const pdf = pdfjs.getDocument(pdfUrl);
  return pdf.promise.then(function (pdf) { // get all pages text
    const maxPages = pdf._pdfInfo.numPages;
    const countPromises = []; // collecting all page promises
    for (let j = 1; j <= maxPages; j++) {
      const page = pdf.getPage(j);
      countPromises.push(page.then(function (page) { // add page promise
        const textContent = page.getTextContent();
        return textContent.then(function (text) { // return content promise
          return text.items.map(function (s) { return s.str; }).join(''); // value page text 
        });
      }));
    }
    return Promise.all(countPromises).then(function (texts) {
      return texts.join('');
    });
  });
}


function HomePage() {
  const [checked, setChecked] = useState(false);
  const [inputText, setInputText] = useState<String | null>("")
  const [language, setLanguage] = useState<String | null>()
  const [cefrLevel, setCefrLevel] = useState<String | null>()
  const [fileName, setFilename] = useState<String | null>()

  return (
    <Main>
      <ImageContainer>
        {/* <img class="Sirv" data-src="https://zakariyyay.sirv.com/Images/lecvi_main.png" alt="">
          <script src="https://scripts.sirv.com/sirvjs/v3/sirv.js"></script> */}
        <MainImage src="https://zakariyyay.sirv.com/Images/lecvi_main.webp" alt="main background" />
      </ImageContainer>
      <MainContainer>
        <MainContainerInner >

          <MainText>
            Improve your language
          </MainText>
          <MainText>
            skills with the power of AI
          </MainText>

          <SecondaryTextContainer>
            <SecondaryText>
              You want to read a foreign book or article, but you can't understand all the words? Lecvi will adapt the text to your skills! Choose one of the available languages and discover how easily you can read any text using artificial intelligence. How does it work? It's simple:
            </SecondaryText>
          </SecondaryTextContainer>
          <InstrFlex>
            <Flex>
              <InstructionCircle text={1} />
              <InstrText>Pick your text</InstrText>
            </Flex>
            <Flex>
              <InstructionCircle text={2} />
              <InstrText>Choose your language level</InstrText>
            </Flex>
            <Flex>
              <InstructionCircle text={3} />
              <InstrText>Read!</InstrText>
            </Flex>
          </InstrFlex>

          <Flex style={{ marginTop: "40px" }} $gap={10}>
            <SecondaryText $color={"black"} $fontWeight={!checked ? 600 : 400}> I want to paste text</SecondaryText>
            <Switch
              color="#ff5831"
              styles={{ track: { color: "#ff5831", backgroundColor: "#ff5831", border: "none" } }}
              checked={checked}
              onChange={(event) => {
                setChecked(event.currentTarget.checked)
                setInputText(null)
              }}
            ></Switch>
            <SecondaryText $color={"black"} $fontWeight={checked ? 600 : 400}> I want to upload PDF</SecondaryText>
          </Flex>
          {checked ?
            fileName ?
              <FilenameContainer>
                <InstrText> {fileName}</InstrText>
                <CloseButton variant="transparent" onClick={() => {
                  setFilename(null)
                  setInputText(null)
                }} />
              </FilenameContainer> :

              <FileInputButton setFilename={setFilename} setInputText={setInputText} gettext={gettext} />
            :
            <TextArea placeholder='Paste your text here' onChange={(e) => setInputText(e.target.value)}></TextArea>
          }

          {inputText &&
            <OptionsContainer>
              <Select
                onChange={(val) => {
                  console.log(val);
                  setLanguage(val)
                }}
                classNames={{ input: "textInput" }}
                placeholder="Text Language"
                data={["french", "spanish", "english", "german", "polish", "russian"]}
                allowDeselect={false}
                style={{
                  height: "44px", borderRadius: "20px", borderColor: "red", flex: 1
                }}
                styles={{
                  root: {
                    color: "#eee", backgroundColor: "#EEE", borderColor: "red", flex: 1,
                  },

                  wrapper: {
                    color: "#eee", height: "44px", flex: 1,
                  },
                  input: {
                    height: "44px",
                    borderRadius: 20,
                    borderColor: "#e5dcd9",
                    fontWeight: "600",
                    fontFamily: "",
                    padding: "0 20px",
                    flex: 1,

                  }
                }}  //TODO: should font be montserrat?
              />
              <Select
                onChange={(val) => setCefrLevel(val)}
                placeholder="Your CEFR level"
                classNames={{ input: "textInput" }}
                data={["A1", "A2", "B1", "B2", "C1", "C2"]}
                allowDeselect={false}
                // classNames={{input:"placeholder-test"}}
                style={{ height: "44px", borderRadius: "20px", flex: 1 }}
                styles={{ root: { color: "#eee", backgroundColor: "#EEE", }, wrapper: { color: "#eee", height: "44px", }, input: { height: "44px", borderRadius: 20, borderColor: "#e5dcd9", fontWeight: "600", fontFamily: "Montserrat", padding: "0 20px" }, }}
              />
            </OptionsContainer>
          }
          {inputText && language && cefrLevel &&
            <SendTextButton inputText={inputText} language={language} cefrLevel={cefrLevel} />
          }
        </MainContainerInner>
      </MainContainer>
    </Main>
  )
}

export default HomePage
