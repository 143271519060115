import React from 'react'

import styled from "styled-components"

const Circle = styled.div`
    display:flex;
    width:30px;
    height:30px;
    border-radius:50%;
    align-items:center;
    justify-content:center;
    box-shadow: 5px 0 8px rgba(255, 98, 0, 0.3);
    `

function InstructionCircle({text}) {
  return (
    <Circle>
        <div style={{color:"#ff6200", fontSize:14}}>{text}</div>
    </Circle>
    )
}

export default InstructionCircle