
import React, { useState } from "react";
import AppContext from "./createContext.tsx";

const AppContextProvider = (props: {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {

  const [resultText, setResultText] = useState<[String]>([])
  const [unicode, setUnicode] = useState<number>()
  const [textLength, setTextLength] = useState<number>()
  const [newWords, setNewWords] = useState([])
  const [newGrammarList, setNewGrammarList] = useState([])

  return (
    <AppContext.Provider
      value={{
        resultText : [resultText, setResultText],
        unicode : [unicode, setUnicode],
        textLength : [textLength, setTextLength],
        newWords : [newWords, setNewWords],
        newGrammarList: [newGrammarList, setNewGrammarList],
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
