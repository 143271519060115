import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AppContext from '../context/createContext.tsx';
import GrammarList from "./GrammarList.tsx";
import { useNavigate } from "react-router-dom";
import { Button, Center, Slider } from '@mantine/core';

import classes from './PageSlider.module.css';
import SliderIcon from "./SliderIcon.tsx";

const Background = styled.div`
    min-height:100px;
    border-radius:0.25rem;
    margin-left:3rem;
    margin-right:auto;
    padding:5%;
    border:1px solid #A9A9A9;
    position: relative;
`

const PageRightArrow = styled.div`
    position: absolute;
    top: 60vh;
    right: -2.5rem;
    background-color: #eee;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`

const PageLeftArrow = styled.div`
    position: absolute;
    top: 60vh;
    left: -2.5rem;
    background-color: #eee;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`

const PaginationContainer = styled.div`
    width:80%;
    margin-left:auto;
    margin-right:auto;
    padding: 0rem;
`

const ResultText = styled.text`
    color:#7B8898;
    font-weight:400;
    font-family: Mercury SSm A, "Mercury SSm B", Georgia, Times, "Times New Roman", "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun, STXihei, "华文细黑", serif;
    font-size:20px;
    font-weight:500
    `

const MainContainer = styled.div`
    width:100vw;
    display:flex;
    flex-direction:column;
`

const SideBar = styled.div`
    display:flex;
    width: fit-content; 
    flex-direction:column;
    padding:15px;
    padding-bottom:20px;
    box-shadow: 0px 0px 56px -27px rgba(66, 68, 90, 1);    
    border-radius: 35px;
    height: fit-content;
`
// -webkit-box-shadow: 0px 0px 46px -34px rgba(66, 68, 90, 1);
// -moz-box-shadow: 0px 0px 46px -34px rgba(66, 68, 90, 1);
// border: 1px solid black;


const Divider = styled.div`
    border-top:1px solid #d8d8d8;
    width: 100%;
    margin: 15px auto;
`

const ExpandIcon = styled.div`
    display: flex;
    width: 42px;
    height:42px;
    background-color: #f4f4f4;
    border-radius: 50%;
`

const SideBarContainer = styled.div`
    display:flex;
    flex-direction: column;
    max-width:450px;
`

const Flex = styled.div`
    display: flex;
`

const ClickedComponent = styled(Flex)`
    flex-direction: column;
    background-color: #f4f4f4;
    padding:10px;
    max-width:420px;
    border-radius: 10px;
    margin-top:15px
`

const OrangeDot = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: orange;
`

function ResultPage() {
    const [isExpanded, setIsexpanded] = useState(false)

    const navigate = useNavigate()
    const {
        resultText: [resultText, setResultText],
        newWords: [newWords, setNewWords],
        newGrammarList: [newGrammarList, setNewGrammarList]
    } = useContext(AppContext)!;

    const [selectedWord, setSelectedWord] = useState({})
    const [selectedGrammer, setSelectedgrammer] = useState({})
    const [expandGrammar, setExpandGrammar] = useState(true)
    const [expandWords, setExpandWords] = useState(true)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const totalPages = resultText?.length as number

    return (
        <MainContainer>
            <Button onClick={() => {
                setResultText([])
                setNewGrammarList([])
                navigate("/")
            }
            } style={{ width: "350px", borderRadius: 20, height: "44px", backgroundColor: "#ff5831", marginTop: "40px", marginBottom:"2em", marginLeft: "10%" }}>
                Choose different file
            </Button>
            
            
            <Flex style={{ width: "80%", marginLeft: "10%" }}>
                <SideBar>
                    <ExpandIcon style={{ justifyContent: "center", alignItems: "center" }} onClick={() => setIsexpanded(!isExpanded)}>
                        <img src={isExpanded ? 'chevronleft.svg' : 'chevronright.svg'} width={15} height={15} />
                    </ExpandIcon>
                    <Divider></Divider>
                    <SideBarContainer>
                        <Flex style={{ alignItems: "center", justifyContent: "flex-start" }}>
                            <img src='wordstolearn-01.svg' style={{ width: "22px", height: "22px", margin: !isExpanded ? "0 auto" : "0 0" }}></img>
                            {
                                isExpanded &&
                                <text style={{ marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px" }}> Words to learn</text>
                            }
                            {isExpanded &&
                                <img src='chevronup.svg' style={{ marginLeft: "auto", width: "10px", height: "10px", cursor: "pointer" ,transform: `rotate(${!expandWords?"180deg" : "0deg"} )`}}
                                    onClick={() => setExpandWords(!expandWords)}

                                ></img>
                            }
                        </Flex>
                        {isExpanded && expandWords &&
                            <Flex style={{ flexWrap: "wrap", maxWidth: "420px", marginTop: "12px", gap: "6px",minWidth:"250px" }}>
                                 {newWords?.map((wordObject, index) => (
                                    Object.keys(wordObject).map((key) => (
                                        <Flex style={{ alignItems: "center", gap: "6px" }}>
                                            <OrangeDot />
                                            <text style={{ fontFamily: "Montserrat",textWrap:"wrap", fontSize: "14px", cursor: "pointer", fontWeight: selectedWord.key == key ? "bold" : "normal",  }} onClick={
                                                () => {
                                                    setSelectedgrammer({})
                                                    setSelectedWord({key:key, definition:wordObject[key]})
                                                }
                                            } >{key}</text>
                                        </Flex>))
                                ))}
                            </Flex>
                        }
                        {isExpanded &&  Object.keys(selectedWord).length>0 && expandWords &&
                            <ClickedComponent>
                                <text style={{ fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px" }}>
                                    {selectedWord.key}
                                </text>

                                <text style={{ fontFamily: "Montserrat", fontSize: "14px", marginTop: "10px" }}>
                                    {selectedWord.definition}
                                </text>

                            </ClickedComponent>
                        }

                    </SideBarContainer>
                    <Divider></Divider>
                    <SideBarContainer>
                        <Flex style={{ alignItems: "center", justifyContent: "flex-start", minWidth:isExpanded?"250px":"" }}>
                            <img src='grammar-01.svg' style={{ width: "22px", height: "22px", margin: !isExpanded ? "0 auto" : "0 0" }}></img>
                            {
                                isExpanded &&
                                <text style={{ marginLeft: "10px", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px" }}> Grammar structures</text>
                            }
                            {isExpanded &&
                                <img src='chevronup.svg' style={{ marginLeft: "auto", width: "10px", height: "10px", cursor: "pointer", transform: `rotate(${!expandGrammar?"180deg" : "0deg"} )` }} onClick={() => { setExpandGrammar(!expandGrammar) }}></img>
                            }
                        </Flex>
                        {isExpanded && expandGrammar &&
                            <Flex style={{ flexWrap: "wrap", maxWidth: "420px", marginTop: "12px", gap: "6px", minWidth:"250px" }}>
                                {newGrammarList?.map((wordObject, index) => (
                                    Object.keys(wordObject).map((key) => (
                                        <Flex style={{ alignItems: "center", gap: "6px" }}>
                                            <OrangeDot />
                                            <text style={{ fontFamily: "Montserrat",textWrap:"wrap", fontSize: "14px", cursor: "pointer", fontWeight: selectedGrammer.key == key ? "bold" : "normal",  }} onClick={
                                                () => {
                                                    setSelectedgrammer({key:key, definition: wordObject[key]})
                                                    setSelectedWord({})
                                                }
                                            } >{key}</text>
                                        </Flex>))
                                ))}
                            </Flex>
                        }
                        {isExpanded && Object.keys(selectedGrammer).length>0 && expandGrammar &&
                            <ClickedComponent>
                                <text style={{ fontFamily: "Montserrat", fontWeight: "bold", fontSize: "14px" }}>
                                    {selectedGrammer.key}
                                </text>

                                <text style={{ fontFamily: "Montserrat", fontSize: "14px", marginTop: "10px" }}>
                                    {selectedGrammer.definition}
                                </text>

                            </ClickedComponent>
                        }


                    </SideBarContainer>
 
                </SideBar>
                <Background>
                    {currentPage > 1 && (<PageLeftArrow onClick={() => setCurrentPage(currentPage-1)}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="36"  height="36"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                    </PageLeftArrow>)}
                    <ResultText>
                        <div dangerouslySetInnerHTML={{ __html: resultText?.at(currentPage-1) as string }} />
                    </ResultText>
                    {currentPage < totalPages && (<PageRightArrow onClick={() => setCurrentPage(currentPage+1)}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="36"  height="36"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                    </PageRightArrow>)}
                </Background>
            </Flex>
            
            <PaginationContainer>
                <Center my={10}>
                    Page {currentPage}/{totalPages}
                </Center>
                <Slider
                    defaultValue={1}
                    thumbChildren={<SliderIcon />}
                    color="grey"
                    min={1}
                    step={1}
                    label={(val) => val}
                    size={2}
                    max={totalPages}
                    value={currentPage}
                    classNames={classes}
                    onChange={setCurrentPage}
                    thumbSize={70}
                /> 
            </PaginationContainer>
            
        </MainContainer>
    )
}

export default ResultPage