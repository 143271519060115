import React from 'react'
import styled from 'styled-components';
import InstructionCircle from './InstructionCircle';

const Flex = styled.div <{ $gap?: number; $margin?: number, $direction?: string }>`
  display:flex;
  align-items:start;
  flex:1;
  flex-direction: ${props => props.$direction || "row"};
  margin:0 ${props => props.$margin || 0}vw 0;
  gap:${props => props.$gap || 0}px;
`

//   justify-content:center;
const MainText = styled.div`
    font-size:26px;
    font-weight:bold;
    line-height:130%;
    font-family: Montserrat;
    @media (max-width: 768px) { 
      text-align: center;
    }
    
`

const InstrFlex = styled.div`
  display:flex;
  
  flex: 0 0 100%;
  flex-basis: 100%;
  width: 100%;
  margin-top:30px;
`
const InstrText = styled.div`
  font-size:14px; 
  font-weight:600;
  margin-left:15px;
  margin-right:10px;
  font-family: Montserrat;
  `




const SecondaryText = styled.div<{ $marginTop?: number; $color?: string, $fontWeight?: number }>`
  font-size:14px;
  font-weight:${props => props.$fontWeight || 500};
  font-family: Montserrat;
  color:${props => props.$color || "black"};
  margin-top:${props => props.$marginTop || 0}px;
`

function HowItWorks() {
    return (
        <Flex $margin={16} $direction='column'>
            <Flex style={{ marginTop: 100 }}>
                <Flex $direction='column' style={{}}>
                    <MainText>Lecvi helps you learn languages easier</MainText>
                    <SecondaryText $marginTop={20}>
                        We use AI to simplify the text to a level that you can understand. This way, you can comfortably read a book or publication even if your language level does not allow it.
                    </SecondaryText>
                </Flex>
                <img src='aboutImage.png' style={{ flex: 1, width: 0 }}></img>


            </Flex>
            <InstrFlex  style={{gap:"50px"}}>
                <div style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                    <Flex $gap={10} style={{ alignItems: "center" }}>
                        <InstructionCircle text={1} />
                        <InstrText>Pick your text</InstrText>
                    </Flex>
                    <text style={{fontFamily: "Montserrat", fontSize: "14px" }}>
                        Select a PDF file from your disk or paste the fragment you want to process with Lecvi. Remember that the longer the text, the longer the waiting time for the result.
                    </text>
                </div>

                <div  style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                    <Flex $gap={10} style={{ alignItems: "center" }}>
                        <InstructionCircle text={2} />
                        <InstrText>Choose your language level</InstrText>
                    </Flex>
                    <text  style={{fontFamily: "Montserrat", fontSize: "14px" }}>
                        Our models are based on CEFR levels. We are constantly expanding the list of languages you can use. Would you like to see your selected language on the list? Contact us.
                    </text>
                </div>

                <div  style={{display:"flex", flexDirection:"column", gap:"1rem"}}>
                    <Flex $gap={10} style={{ alignItems: "center" }}>
                        <InstructionCircle text={3} />
                        <InstrText>Read!</InstrText>
                    </Flex>
                    <text  style={{fontFamily: "Montserrat", fontSize: "14px" }}>
                        Your new text will be generated. Now grab your tea and you're ready to read!
                    </text>
                </div>

            </InstrFlex>

        </Flex>

    )
}

export default HowItWorks